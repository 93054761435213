<template>
  <b-row>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <h5>Price group configuration</h5>
      <p class="">Create and cofigurate different discounts price group list by user</p>
    </div>
    <div class="col-lg-3 col-md-12 col-sm-12">
      <ul class="list-group pb-4">
        <li class="list-group-item  d-flex justify-content-between" style="background: black; color: white">
          <span>Name</span>
          <span class="text-right">Make default <i class="material-icons color-red"
                                                   style="width: 1.5rem">checked</i></span>
        </li>
        <li class="list-group-item-action list-group-item d-flex justify-content-between"
            v-for="(item, index) of groupList"
            :class="{'selected': itemSelected && item._id === itemSelected._id}"
            @click="select(item, index)">

          <span>{{ item.name }}</span>
          <span :title="(item.default? 'This is the default pricelist.' : 'Mark as default')">
           <div style="display: flex; justify-content: space-between">
              <b-checkbox :checked="item.default" :disabled="item.default" @change="makeDefault(item)"/>
           </div>
          </span>

        </li>
      </ul>
      <b-button class="mt-4" variant="dark" block @click="createNewPriceGroup">
        Create new group
      </b-button>
    </div>
    <div class="col-lg-9 col-md-12 col-sm-12">
      <div class="" v-if="itemSelected">
        <h4 class="">Price group selected: <span :class="changePrice"> {{ itemSelected.name.toUpperCase() }}</span></h4>
        <span v-if="itemSelected.default">By default all your dealers / subdealers see the default group. It is not necessary to add them.</span>
        <hr>
        <div v-if="!$userCan('administrator')">
          <div class="d-flex">
            <div class="mr-4">
              <label>Name: </label>
              <b-input v-model="itemSelected.name"/>
            </div>
            <div class="mr-4">
              <div><label>Options</label></div>
              <div>
                <b-button class="btn btn-primary" @click="updateGroup">UPDATE</b-button>
                <b-button class="btn btn-danger" @click="deleteList">DELETE PRICE LIST</b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="$userCan(['administrator'])" class="row" style="width: 100%">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <label>Name:</label>
            <b-input style="width: 100%" v-model="itemSelected.name"/>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <label>Personal Flasher %</label>
            <b-input style="width: 100%" type="number" min="1" max="100" v-model.number="itemSelected.overrunPf"/>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div>
              <label>Options</label>
            </div>
            <div class="btn-group">
              <b-button class="btn btn-primary" @click="updateGroup"> UPDATE</b-button>
              <b-button class="btn btn-danger" @click="deleteList">DELETE PRICE LIST</b-button>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <b-tabs fill justified class="custom-tab" active-nav-item-class="active-tab" v-if="itemSelected">
        <b-tab>
          <template #title>
            Price
          </template>
          <b-row>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-2 mb-2">
              <h6>(Administrate here the price list and discount for the selected customer group)</h6>
            </div>
            <b-col class="col-lg-3 col-md-12 col-sm-12">
              <div>
                <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                  <form @submit.prevent="handleSubmit(save)">
                    <div class="form-group">
                      <label>Credits (<img
                        :src="symbol"
                        class="symbol">)</label>
                      <ValidationProvider rules="required" name="Credits">
                        <div slot-scope="{ errors, validated, valid }">
                          <b-form-input v-model.number="model.credit" type="number" :state="validated ? valid:null"
                                        @input="calculateDiscount"/>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Discount</label>
                      <ValidationProvider rules="required|discount:65" name="discount" v-slot="{errors}">
                        <b-form-input v-model.number="model.discount"
                                      name="discount"
                                      id="discount"
                                      @input="calculateDiscount"
                                      type="number"
                                      :state="validated ? valid:null"/>
                        <span class="error_field" v-if="errors.length">{{ $t(`${errors[0]}`) }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <label>Price ({{ currencyForExchange.symbol }})</label>
                      <ValidationProvider rules="required" name="Price">
                        <div slot-scope="{ errors, validated, valid }">
                          <b-form-input v-model.number="model.price" readonly
                                        type="number" :state="validated ? valid:null"/>
                        </div>
                      </ValidationProvider>
                    </div>
                    <div>
                      <b-button class="mt-4" block-level variant="primary" type="submit"
                                v-tippy="{ placement : 'top',  arrow: true }" :content="'Save price list'">
                        <i class="material-icons">save</i>
                        {{ $t('form.save') }}
                      </b-button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </b-col>
            <b-col class="col-lg-9 col-md-12 col-sm-12 ">
              <div class="table-responsive" style="width: 100%">
                <table class="table table-hover table-sm table-bordered" v-if="itemSelected">
                  <thead>
                  <tr>
                    <th>Credits</th>
                    <th>Discount</th>
                    <th>Price ({{ $store.state.geo.userCurrency.symbol }})</th>
                    <th>Options</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(price, i) of itemSelected.priceList" :key="i">
                    <td>
                      <credits-view :amount="price.credit"/>
                    </td>
                    <td>{{ price.discount }}%</td>
                    <td>
                      <currency-price-view :amount="price.price"/>
                    </td>
                    <td style="justify-content: center; text-align: center">
                      <b-button class="" style="margin: 0;padding: 0.3vw;" variant="primary"
                                @click="deletePrice(price.id)"
                                v-tippy="{ placement : 'top',  arrow: true }" :content="'Remove price'"><i
                        class="material-icons">close</i>
                      </b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            Customers {{ itemSelected.usersIn.length }}
          </template>
          <b-alert class="text-center" variant="dark" :show="itemSelected.default">
            <h6 class="m-0 text-white">This is the default price group, no need to add users.</h6>
          </b-alert>
          <div class="col-lg-12 col-md-12 col-sm-12 mt-2 mb-2">
            <h6>Add or Remove customers in this group.</h6>
          </div>
          <price-users-management v-bind:price-group="itemSelected" v-bind:group-list="groupList"
                                  @onUpdatedList="updateList"/>
        </b-tab>
      </b-tabs>
    </div>
  </b-row>
</template>

<script>
import {get, post} from '@/services/api';
import {mapActions, mapGetters, mapState} from 'vuex';
import PriceUsersManagement from '@/views/dashboard/credit-price/PriceUsersManagement';
import CreditsView from '@/components/common/CreditsView';
import CurrencyPriceView from '@/components/common/CurrencyPriceView';
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'Price',
  mixins:[show_alert_mixin],
  components: {
    CurrencyPriceView,
    CreditsView,
    PriceUsersManagement
  },
  data: () => ({
    groupList: [],
    itemSelected: null,
    model: {
      credit: null,
      discount: null,
      price: null,
      overrunPf: 15
    },
    changePrice: '',
  }),
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol', 'localCreditsToTvsCredits']),
    ...mapState('geo', ['tvsCurrency', 'userCurrency', 'showInLocal']),
    currencyForExchange() {
      let currency = this.tvsCurrency;
      if (this.$userCan(['distributor', 'dealer', 'subdealer']) && this.showInLocal) {
        currency = this.userCurrency;
      }
      return currency;
    },
    symbol() {
      const code = this.currencyForExchange.code.toLowerCase();
      try {
        return require(`@/assets/images/credits/${code}.svg`);
      } catch (e) {
        return require(`@/assets/images/credits/default.svg`);
      }
    }
  },
  methods: {
    ...mapActions('buySale', ['loadInformation']),
    load() {
      this.changePrice = '';
      get('credit-price-config', null, true)
        .then(({data}) => {
          this.groupList = data.map(el => ({
            ...el,
            overrunPf: (el.overrunPf * 100)
          }));
          this.groupList.forEach((el) => {
            if (el.default) {
              this.itemSelected = el;
            }
          });
          if (!this.groupList.length) {
            this.createDefault();
          }
        });
    },
    createDefault() {
      post('credit-price-config', {name: 'Default'}, true)
        .then(({data}) => {
          this.groupList = [data];
          this.itemSelected = data;
        });
    },
    createNewPriceGroup() {
      this.$swal({
        title: 'Enter a group name',
        input: 'text',
        confirmButtonText: 'OK',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(result => {
          if (result.isConfirmed) {
            if (result.value !== null && result.value !== "") {
              post('credit-price-config', {name: result.value, overrunPf: 0.15}, true)
                .then(({data}) => this.groupList.push(data));
            } else {
              this.showAlertNotification("all fields are required", "error")
            }
          }
        });
    },
    select(item) {
      this.itemSelected = item;
      this.groupList = this.groupList.map(el => ({
        ...el,
        selected: el._id === item._id
      }));
      this.changePrice = 'heart'
      setTimeout(() => {
        this.changePrice = '';
      }, 4000)
    },
    makeDefault(item) {
      this.groupList = this.groupList.map(el => ({
        ...el,
        default: el._id === item._id
      }));
      post(`credit-price-config/make-default/${item._id}`).then();
    },
    calculateDiscount() {
      if (this.model.credit > 0 && this.model.discount > 0) {
        const percent = (1 - this.model.discount / 100);
        this.model.price = Math.round(this.model.credit * percent);
      } else {
        this.model.price = this.model.credit;
      }
    },
    deletePrice(id) {
      this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        title: 'Do you want to delete this price?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(async res => {
          if (res.isConfirmed) {
            post(`credit-price-config/${this.itemSelected._id}/remove/${id}`, null, true)
              .then(({data}) => {
                this.itemSelected = null
                this.clearForm();
                this.itemSelected = data;
                this.showAlertNotification("Success")
              });
          }
        });
    },
    save() {
      if (this.model.credit <= 10000000) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Do you want to save changes? The information will be visible to your customers.',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
        })
          .then(async res => {
            if (res.isConfirmed) {
              const postData = {
                credit: this.localCreditsToTvsCredits(this.model.credit, this.currencyForExchange),
                discount: this.model.discount,
                price: this.localCreditsToTvsCredits(this.model.price, this.currencyForExchange),
              };
              post(`credit-price-config/${this.itemSelected._id}/add`, postData, true)
                .then(({data}) => {
                  this.loadInformation();
                  this.updateList(data);
                  this.clearForm();
                  this.showAlertNotification("Success")
                });
            }
          });
      } else {
        this.showAlertNotification("The maximum amount credits are 10.000.000", "error")
      }
    },
    clearForm() {
      this.model = {
        credit: null,
        discount: null,
        price: null,
        overrunPf: 15
      };
      this.$refs.form.reset();
    },
    updateList(object) {
      if (object.overrunPf < 1) {
        object.overrunPf = object.overrunPf * 100;
      }
      if (this.itemSelected._id === object._id) {
        this.itemSelected = object;
      }
      this.groupList = this.groupList.map(el => {
        if (el._id === object._id) {
          return object;
        }
        return el;
      });
    },
    updateGroup() {
      if (this.itemSelected.name !== null && this.itemSelected.name !== "") {
        if (this.$userCan('administrator')) {
          if (this.itemSelected.overrunPf == 0) {
            this.$swal({
              title: 'Are you sure? PF value is ZERO',
              icon: 'question',
              confirmButtonText: 'YES',
              cancelButtonText: 'NO',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonColor: '#d14343',
              cancelButtonColor: '#000000',
            })
              .then(async res => {
                if (res.isConfirmed) {
                  this.processUpdateGroup()
                }
              });
          } else {
            this.processUpdateGroup()
          }
        } else {
          this.processUpdateGroup()
        }
      } else {
        this.showAlertNotification("Wrong information", "error")
      }
    },
    processUpdateGroup() {
      post(`credit-price-config/update/${this.itemSelected._id}`, {
        name: this.itemSelected.name,
        overrunPf: this.itemSelected.overrunPf / 100
      }, true)
        .then(el => {
          this.showAlertNotification("Success")
          this.updateList(el);
        });
    },
    deleteList() {
      this.$swal({
        title: 'Are you sure?',
        icon: 'question',
        confirmButtonText: 'OK',
        cancelButtonText: 'No',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#d14343',
        cancelButtonColor: '#000000',
      })
        .then(async res => {
          if (res.isConfirmed) {
            post(`credit-price-config/delete/${this.itemSelected._id}`, null, true)
              .then(() => {
                this.load();
              });
          }
        });
    },
  }
};
</script>

<style scoped lang="scss">
.remove {
  position: absolute;
  right: 4px;
  padding: 5px;
  top: 50%;
  transform: translate(0, -50%);
}

.list-group-item-action {
  cursor: pointer;
}

.list-group-item {
  padding: 1rem;

  &.selected {
    background: #dee2e6;
    color: black;
  }
}

.heart {
  width: 100%;
  color: #d14343;
  padding: 0.3vw;
  animation: beat 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    text-shadow: #d14343 5px 5px 20px;
    //border-radius: 10px;
  }
}
</style>
