<template>
  <div style="width: 100%; display: flex; flex-direction: column" class="mt-3"
       v-if="selected !==null">
    <textarea name="description" id="description" cols="30" rows="10" v-model="infoData"></textarea>
    <div style="text-align: center;">
      <b-button @click="saveDescription()" type="submit" v-if="selected.type.toString() === 'create'">Create
        New Description Configuration
      </b-button>
      <b-button @click="editDescription()"
                class="btn btn-primary"
                v-if="selected !==null && selected.creditOrder === null">Edit Description Configuration
      </b-button>

      <b-alert v-if="selected !==null && selected.creditOrder !== null && selected.type.toString() !== 'create'"
               variant="dark" show dismissible><span
        style="font-weight: bold">  It is not possible to update the information. There are credit orders assigned   </span>
      </b-alert>
    </div>
  </div>

</template>

<script>

import {post} from "@/services/api";
import {invoiceUpdateDescription, invoiceCreateDescription} from "@/services/endpoint.json";
import {mapActions} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Description",
  props: ['infoDescription', 'currentD'],
  mixins: [show_alert_mixin],
  computed: {
    description() {
      return this.infoDescription;
    },
    selected() {
      return this.currentD;
    }
  },
  watch: {
    description: {
      immediate: true,
      handler(info) {
        this.infoData = info

      }
    },
    selected: {
      immediate: true,
      handler(e) {
      }
    },
  },
  data: () => ({
    infoData: null,
  }),
  methods: {
    ...mapActions('buySale', ['loadInformation']),
    async saveDescription() {
      if (this.infoData !== null && this.infoData !== "" && this.selected.id !== undefined) {
        await post(invoiceCreateDescription, {id: this.selected.id, description: this.infoData}, true)
        this.infoData = null
        this.$emit('reloadData')
        this.showAlertNotification("Success")
      }
    },
    async editDescription() {
      if (this.infoData !== null && this.infoData !== "" && this.selected.id !== undefined) {
        await post(invoiceUpdateDescription, {id: this.selected.id, description: this.infoData}, true)
        this.infoData = null
        this.$emit('reloadData')
        this.showAlertNotification("Success")
      }
    },
  }
}
</script>

<style scoped>

</style>
