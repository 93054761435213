<template>
  <div>
    <b-card-header>Payment info</b-card-header>
    <b-card-body>
      <b-row>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <div class="form-group">
            <label>Account Holder</label>
            <b-input id="accountHolder" class="mb-2 mr-sm-2 mb-sm-0" v-model="infoData.accountHolder"
                     placeholder="Account Holder"/>
          </div>
          <div class="form-group">
            <label>Account</label>
            <b-input id="account" class="mb-2 mr-sm-2 mb-sm-0" v-model="infoData.account" placeholder="Account"/>
          </div>
          <div class="form-group">
            <label>Swift Code</label>
            <b-input id="swiftCode" class="mb-2 mr-sm-2 mb-sm-0" v-model="infoData.swiftCode" placeholder="Swift Code"/>
          </div>
          <div class="form-group">
            <label>Address</label>
            <b-input id="address" class="mb-2 mr-sm-2 mb-sm-0" v-model="infoData.address" placeholder="Address"/>
          </div>

          <div style="width: 100%; display: flex; justify-content: space-around" class="mt-3">
            <b-button :class="{ 'heart': redirectBack }" type="submit" @click="saveInvoiceConfiguration()">Save Payment
              Info
            </b-button>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 mobile">
          <InvoiceView :invoice-titles="invoicesTitles" :companyOwner="infoData" :order="order" :fixedbottom="false"
                       :showDownload="showDownload"
                       :dummy="true" :is-distributor="true"/>
        </div>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {post} from "@/services/api";
import {InvoiceConfigurationSave} from "@/services/endpoint.json";
import InvoiceView from "@/views/dashboard/credit-order-detail/InvoiceView";
import {mapActions,} from "vuex";
import {invoicesTitlesDistributors} from "@/constants";
import show_alert_mixin from "@/utils/show_alert_mixin";

const resolution = `<div>This document is a confirmation of a successful TVS software credits allocation from a Distributor to a (Sub-)Dealer. Actual invoicing, payments and receipt confirmations are actions between the Distributor and Dealer directly. TVS has no responsibility for any actions during these steps.</div> <br> <div style="justify-content: center; display: flex;"><span>TVS terms & conditions are applicable.   </span>   <span> Copyright TVS Groupinternational 2022</span></div>`

const defaultData = {
  title: '',
  subtitle: '',
  start_at: '',
  end_at: '',
  prefix: '',
  current_n: '',
  resolution: resolution,
  country: '',
  street: '',
  zipcode: '',
  city: '',
  taxnr: '',
  resolutionNr: '',
  email: '',
  quotationPrefix: '',
  accountHolder: '',
  account: '',
  swiftCode: '',
  address: '',
}
export default {
  name: "Invoice",
  components: {InvoiceView},
  props: ['infoInvoiceConfiguration', 'infoRedirectBack'],
  computed: {
    invoiceConfig() {
      return this.infoInvoiceConfiguration;
    },
    redirectBack() {
      return this.infoRedirectBack;
    }
  },
  watch: {
    invoiceConfig: {
      deep: true,
      handler(info) {
        if (info !== null) {
          this.infoData = info
          this.order.invoiceNumber = `${this.infoData.prefix}${this.infoData.current_n}`;
        }
      }
    },
    redirectBack: {
      deep: true,
      handler(e) {
      }
    },
  },
  mixins: [show_alert_mixin],
  data: () => ({
    showDownload: false,
    editorConfig: {
      language: 'en',
      preview: true,
      height: 800
    },
    editorData: '',
    invoicesTitles: invoicesTitlesDistributors(),
    infoData: {},
    order: {
      createdAt: "2022-05-06T16:38:06.000Z",
      credit: 2200,
      description: "Offer Credit",
      discount: 32,
      id: 2,
      invoiceNumber: "TV002",
      ownerId: null,
      payments: [],
      price: 1500,
      status: "Payment confirmed",
      user: {
        country: "",
        email: "",
        fullName: "",
        id: 3,
        lastName: "",
        name: "",
        phone: '',
        status: 1,
        term: 1,
        updatedAt: "",
        username: null,
      }
    },
    classCustom: '',
  }),
  methods: {
    ...mapActions('buySale', ['loadInformation']),

    async saveInvoiceConfiguration(e) {
      if (this.infoData.account !== '' && this.infoData.accountHolder !== "" && this.infoData.address !== "" && this.infoData.swiftCode !== "") {
        this.infoData.role = localStorage.getItem('admin-type')
        // this.infoData.userId = localStorage.getItem('admin-id')
        this.$swal({
          title: 'Are you sure you want to save the information?',
          showCancelButton: true,
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await post(InvoiceConfigurationSave, this.infoData, true)
            this.loadInformation().then()
            if (this.infoRedirectBack) {
              this.$emit('redirectPage')
            } else {
              this.$emit('reloadData')
            }
            this.infoData = defaultData
            this.showAlertNotification("Success")
          }
        });
      }
    },

  }
}
</script>

<style scoped>
.heart {
  width: 100%;
  color: white;
  animation: beat 1s infinite alternate;
}

@keyframes beat {
  to {
    box-shadow: 0 0 10px #d14343;
  }
}

@media only screen and (max-width: 600px) {
  .mobile {
    display: none;
  }
}
</style>
