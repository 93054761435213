<template>
  <b-overlay :show="makingChange">
    <b-row>
      <div class="table-responsive col-lg-6 col-md-12 col-sm-12">
        <table class="table table-bordered">
          <tr>
            <th>
              Name
              <b-input placeholder="Filter" v-model="filterAvailableList"/>
            </th>
            <th>Country</th>
            <th>Current list</th>
            <th>Options</th>
          </tr>
          <tr v-for="user of availableUers">
            <td>{{ user.fullName }}</td>
            <td>
              <span v-if="user.country">{{ $store.getters["geo/country"](user.countryId).alpha3Code}}</span>
              <span v-else>{{user.country}}</span>
            </td>
            <td>
              <template v-if="user.inList">{{ user.inList.name }}</template>
              <template v-else>Default.</template>
            </td>
            <td>
              <b-button class="btn-primary" @click="addTothisList(user)">
                <i class="material-icons">add</i>
              </b-button>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <table class="table table-bordered">
          <tr>
            <th>Name</th>
            <th>Country</th>
            <th>Options</th>
          </tr>
          <tr v-for="user of inThisList">
            <td>{{ user.fullName }}</td>
            <td>
              <span v-if="user.country">{{ $store.getters["geo/country"](user.countryId).alpha3Code}}</span>
              <span v-else>{{user.country}}</span>
            </td>
            <td>
              <b-button class="btn-danger" @click="removeFromThisList(user)">
                <i class="material-icons">delete</i>
              </b-button>
            </td>
          </tr>
        </table>
      </div>
    </b-row>
  </b-overlay>
</template>

<script>
import { get, post } from '@/services/api';

export default {
  name: 'PriceUsersManagement',
  props: {
    priceGroup: {
      required: true,
      type: Object
    },
    groupList: Array
  },
  data: function () {
    return {
      allUSers: [],
      inThisList: [],
      restUser: [],
      makingChange: false,
      filterAvailableList: ''
    };
  },
  computed: {
    availableUers: function () {
      return this.restUser.filter(el => {
        return !this.filterAvailableList || el.fullName.toLowerCase()
          .includes(this.filterAvailableList.toLowerCase());
      });
    }
  },
  methods: {
    getUsers() {
      get('user-network', null, true)
        .then(({ data }) => {
          this.allUSers = data;
          this.organiceUsers();
        });
    },
    organiceUsers() {
      this.restUser = [];
      this.inThisList = [];
      const listCloned = JSON.parse(JSON.stringify(this.groupList))
        .sort((a, b) => a.default === b.default ? 0 : a.default ? 1 : -1);
      this.allUSers = this.allUSers.map((user) => {
        if (this.priceGroup.usersIn.includes(user.id)) {
          this.inThisList.push(user);
        } else {
          listCloned.forEach(el => {
            if (el.usersIn.includes(user.id)) {
              user.inList = el;
              return true;
            }
          });
          this.restUser.push(user);
        }
        return user;
      });

    },
    addTothisList(user) {
      this.makingChange = true;
      if (user.inList) {
        post(`credit-price-config/${user.inList._id}/remove-user/${user.id}`)
          .then(({ data }) => this.$emit('onUpdatedList', data));
      }
      post(`credit-price-config/${this.priceGroup._id}/add-user/${user.id}`)
        .then(({ data }) => {
          this.priceGroup = data;
          this.groupList = this.groupList.map(el => {
            if (el._id == this.priceGroup._id) {
              return this.priceGroup;
            }
            return el;
          });
        })
        .finally(() => this.makingChange = false);
    },
    removeFromThisList(user) {
      post(`credit-price-config/${this.priceGroup._id}/remove-user/${user.id}`)
        .then(({ data }) => {
          this.priceGroup = data;
          this.groupList = this.groupList.map(el => {
            if (el._id == this.priceGroup._id) {
              return this.priceGroup;
            }
            return el;
          });
        })
        .finally(() => this.makingChange = false);
    }
  },
  watch: {
    priceGroup: function () {
      this.$emit('onUpdatedList', this.priceGroup);
      this.organiceUsers();
    },
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style scoped>

</style>
