<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <ckeditor style="min-height: 800px" v-model="infoData" :config="editorConfig"></ckeditor>
      <div style="width: 100%; display: flex; justify-content: space-around" class="mt-3" v-if="selected !==null">
        <b-button @click="savePaymentInformation()" type="submit" v-if="selected.type.toString() === 'create'">Create
          New
          Information Payment
        </b-button>
        <b-button @click="editPaymentInformation()"
                  class="btn btn-primary"
                  v-if="selected !==null && selected.creditOrder === null">Edit Information Payment
        </b-button>

        <b-alert v-if="selected !==null && selected.creditOrder !== null && selected.type.toString() !== 'create'"
                 variant="dark" show dismissible><span
          style="font-weight: bold">  It is not possible to update the information. There are credit orders assigned   </span>
        </b-alert>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" v-html="infoData"></div>
  </div>

</template>

<script>
import {post} from "@/services/api";
import {PaymentConfigurationSave, PaymentConfigurationEdit} from "@/services/endpoint.json";
import {mapActions} from "vuex";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "Payment",
  mixins: [show_alert_mixin],
  props: ['infoEditorData', 'currentP'],
  computed: {
    editorData() {
      return this.infoEditorData;
    },
    selected() {
      return this.currentP;
    }
  },
  watch: {
    editorData: {
      immediate: true,
      handler(info) {
        this.infoData = info
      }
    },
    selected: {
      immediate: true,
      handler(e) {
      }
    },
  },
  data: () => ({
    editorConfig: {
      language: 'en',
      preview: true,
      height: 800,
    },
    infoData: null,
    showPreview: false,
  }),

  methods: {
    ...mapActions('buySale', ['loadInformation']),

    savePaymentInformation() {
      if (this.infoData !== null && this.infoData !== "") {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure you want to CREATE NEW payment information?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async res => {
          if (res.isConfirmed) {
            post(PaymentConfigurationSave, {data: this.infoData}, true)
              .then(() => {
                this.loadInformation()
                this.$emit('reloadData')
                this.infoData = {}
                this.showAlertNotification("Success")
              })
          }
        })
      }
    },

    editPaymentInformation() {
      if (this.infoData !== null && this.infoData !== "" && this.selected.id !== undefined) {
        this.$swal({
          showConfirmButton: true,
          showCancelButton: true,
          title: 'Are you sure you want to EDIT CURRENT payment information?',
          confirmButtonColor: "#d14343",
          cancelButtonColor: "#000000",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then(async res => {
          if (res.isConfirmed) {
            await post(PaymentConfigurationEdit, {data: this.infoData, id: this.selected.id}, true)
            this.$emit('reloadData')
            this.loadInformation()
            this.infoData = {}
            this.showAlertNotification("Success")
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
